<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.unassigned-tickets') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.overview') }}</router-link>
            </div>
          </div>
          <div class="subheader-right">
            <v-guard :permissions="['tickets-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="Excel">
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'excel')">{{ $t('labels.tickets') }} A
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.ticketlist.exportComments('unassigned')">{{ $t('labels.events') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
              <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.wait') }}
              </button>
            </v-guard>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <tickets-list
            :enable-filter="$store.getters.permissions.includes('tickets-page-unassigned-use-filter')"
            ref="ticketlist"
            :default-query="query"
            :hide="['users.name.raw']"
            title="Otilldelade ärenden"
        ></tickets-list>
      </div>
    </div>
  </layout-main>
</template>
<script>
import LayoutMain from '@/views/Layouts/Main';
import TicketsList from '@/components/lists/ticket/TicketsList.vue';

export default {
  name: 'unassigned-tickets',
  components: {
    LayoutMain,
    TicketsList,
  },
   data() {
    const excludedYears = ['2017', '2018', '2019', '2020'];
    // breaking this out for clearer logic
    const mustNotConditions = [
        {
          nested: {
            path: 'users',
            query: {
              exists: {
                field: 'users.id',
              },
            },
          },
        },
        { match: {
          'is_anonymized':  1,
        }},
        {
            terms: {'year.raw': excludedYears},
        },
    ];

    // exclude formedlaExternt from unassigned tickets
    const formedlaExternt = this.$store.getters.permissions.includes('ticket-view-formedla_externt');

    // Conditionally exclude "Förmedlad externt" if the user lacks permission
    if(!formedlaExternt) {
      mustNotConditions.push({
        match: {
          ticketstatus: 'Förmedlad externt',
        },
      });
    }

    return {
      isDownloading: false,
      query: {
        query: {
          bool: {
            must_not: mustNotConditions,
          },
        },
      },
    }

  },
};
</script>
